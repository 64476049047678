.container {
    background: #1C1C1C;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
}

.container2 {
    background: #FFFFFF;
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.container3 {
    overflow: hidden;
    background: #FFF9F2;
}

.container4 {
    overflow: hidden;
    background: #1C1C1C;
    height: 100vh;
    color: #FFFFFF;
}


.container::-webkit-scrollbar {
    display: none;
}

.parentDiv {
    margin: 0 auto;
    padding: 65px 150px;
    max-width: 1920px;
}

.parentDiv2 {
    margin: 0 auto;
    padding: 65px 250px;
    max-width: 1920px;
}

.parentDiv3 {
    margin: 0 auto;
    width: 70vw;
    max-width: 1920px;
    padding: 60px;
    // height: 100vh;
    background-color: #fff;
    text-align: center;
    position: relative;
}

img {
    width: auto;
    height: auto;
    aspect-ratio: attr(width) / attr(height);
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 110%;
    height: 110%;
    animation: bounce 3s infinite;
    -webkit-animation: bounce 8s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.div1 {
    display: flex;
    margin-top: 80px
}

.div1left {
    width: 50%;
    justify-content: flex-start;
    align-self: center;

    .header {
        width: 500px;
        line-height: 131.5%;
        margin-top: -20px
    }

    .text {
        width: 450px;
        margin-top: 0;
    }
}

.div1right {
    width: 50%;
    justify-content: flex-start;
    align-self: center;
}

.button1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    background-color: #FB8B19;
    padding: 18px 38px;
    border-radius: 5px;
    width: 100px;
    margin-top: 50px;
    transition: background-color 2s ease-out 100ms;
    cursor: pointer;
}

.button2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #FB8B19;
    padding: 8px 15px;
    border-radius: 5px;
    border: 1px solid #FB8B19;
    width: auto;
    cursor: pointer;

    &:disabled {
        background-color: #c1c1c1;
        border: 1px solid #c1c1c1;
        pointer-events: none;
    }

    &:hover {
        background-color: #ff8200;
        border: 1px solid #ff8200;
    }
}

.button1:hover {
    background: #f29d47;
}

.stepperSubDiv {
    margin: 1.4em;
    border-radius: 12px;
    padding: 1.4em;
}

.stepperSubDiv h1 {
    font-weight: 500;
    text-align: center;
    color: #000000;
}

.stepperSubDiv p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 144.5%;
    color: #272727;
}

.center {
    margin: 20px auto;
    color: #272727;
    font-size: 18px;
    width: 400px;
    text-align: center;
}

.alignCenter {
    text-align: center;
}

.stepperBtn {
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 6px 11px;
    background: #FB8B19;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.progress {
    border-bottom: none !important;
}

.buttonText {
    margin-right: 4px;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 6px 11px;
    background: #C9C9C9;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px
}

.form {
    margin: 80px -100px;
}

.flex {
    display: flex;
    align-items: flex-start;
}

.number {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 144.5%;
    color: #2C8900;
    display: flex;
    align-items: center;
    width: 40px
}

.numberText {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 144.5%;
    color: #000000;
    width: 100%;
    margin-left: 16px;
}

.backButton {
    margin-left: 55px;
    margin-bottom: 40px;
}

.forwardButton {
    margin-left: 20px;
    margin-bottom: 40px;
}

.buttonDiv {
    margin-left: -45px;
    margin-top: -30px;
}

.bold {
    font-weight: 500;
}

.subText {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 175%;
    color: #000000;
}

.mtInput {
    margin-top: 3rem;
}

.mr1 {
    margin-right: 1rem
}

.close {
    position: absolute;
    top: 15px;
    right: 30px;
    color: #9F9F9F;
}



.successHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 144.5%;
    color: #fff;
}

.score {
    font-weight: 500;
    font-size: 24px;
    line-height: 144.5%;
    color: #FB8B19;
    margin-top: 30px;
}

.scoreSubscript {
    font-weight: 400;
    font-size: 12px;
    line-height: 144.5%;
    color: #9F9F9F;
}

.category {
    font-weight: 500;
    font-size: 30px;
    line-height: 144.5%;
    color: #FB8B19;
    margin-top: 20px
}

.categoryDesc {
    margin: 0 auto;
    width: 40vw;
    font-weight: 400;
    font-size: 20px;
    line-height: 144.5%;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 50px;
}

.mail {
    margin: 10px auto;
    padding: 5px 0;
    background-color: rgba(98.4, 47.1, 9.8, 0.2);

    p {
        font-weight: 400;
        font-size: 14px;
        color: #FB8B19;
    }
}


.parentPadding {
    width: 50%;
    margin: 0 auto
}

//not found
.notFoundButton {
    width: 120px;
    height: auto;
    background: #fb8b19;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
}

.notFoundText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
}

.divflexColumn {
    display: flex;
    justify-content: center;
    align-items: center;
}


.imageDiv img {
    width: 100%;
    height: 60vh;
}

//success
.socialMediaDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px
}

.socialMedia {
    display: flex;
}

.socialMediaIcon {
    margin: 15px 10px;
}

.socialMediaText {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 144.5%;
}

.icons {
    background-color: #f1f1f1 !important;
    border-radius: 5px !important;
}



.textfieldDiv {
    display: block;
    margin: 15px auto;

    .urlTextfield {
        background: #404040;
        border-radius: 5px 0 0 5px;
        color: #fff;
        padding: 8px 0 8px 15px;
        border: none;
        font-size: 16px;
    }

    button {
        background-color: #404040;
        border-radius: 0 5px 5px 0;
        border: none;
        color: #fff;
        padding: 8px 15px 8px 0;
        position: relative;
    }

    .copied {
        position: absolute;
        top: -20px;
        left: -12px;
        font-size: 12px !important;
    }
}

//modal style
.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
}

.modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

#cancelBtn {
    background-color: crimson;
}


.error {
    font-size: medium;
}

.m1 {
    margin: 1rem 0;
}

@media (min-width: 1800px) {

    .text {
        width: 700px !important;
    }

    .div1 {
        margin-top: 10vw
    }

    .div2 {
        margin: 0 auto;
    }

    .center {
        margin: 100px auto;
    }

    .parentDiv {
        padding: 80px 300px;
    }

    .parentDiv2 {
        padding: 80px 300px;
    }
}


@media (min-width: 1400px) {

    .stepperBtn {
        margin-left: 5vw;
    }
}


@media only screen and (min-width: 769px) and (max-width: 1100px) {
    .parentDiv {
        padding: 80px;
    }

    .parentDiv2 {
        padding: 80px;
    }

    .parentDiv3 {
        width: 95vw;
    }

    .image {
        width: 90%;
    }

    .header {
        width: 250px !important
    }

    .text {
        width: 350px !important;
        margin-top: -10px !important
    }

    .button1 {
        padding: 14px 38px;
    }

    .stepperSubDiv {
        margin: 2em;
        padding: 2em;
    }


    .form {
        margin: 80px -20px;
    }

    .buttonDiv {
        margin-left: 30px;
    }


}

@media (max-width: 768px) {

    .container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        overflow-y: scroll;
    }


    .parentDiv {
        padding: 20px
    }

    .parentDiv2 {
        padding: 30px
    }

    .parentDiv3 {
        padding: 30px
    }

    .div1 {
        display: block;
    }

    .div1left {
        width: 100%;

        .header {
            width: 85vw;
        }

        .text {
            width: 85vw;
            margin-top: 20px
        }
    }

    .div1right {
        width: 100%;
        margin-top: 50px;
    }

    .image {
        margin: 10px auto;
        display: flex;
        justify-content: center;
        width: 80vw;
    }

    .form {
        margin: 0;

        .flex {
            align-items: flex-start;
        }

        .number {
            font-size: 20px;
        }

        .numberText {
            font-size: 20px;
        }
    }

    .stepperSubDiv {
        margin: 1.4em 1.4em 1.4em 0;
        padding: 1.4em 0;
    }

    .parentPadding {
        width: 80%;
    }


    .imageDiv img {
        height: 50vh;
    }

    .backButton {
        margin-left: 40px;
        margin-top: 20px;
    }

    .buttonDiv {
        margin-left: 35px;
        margin-top: 40px;
    }

    .forwardButton {
        margin-left: 20px;
        margin-top: 20px;
    }


    .socialMedia {
        margin-top: 10px;
        justify-content: center;
        width: 70%;
    }


    .center {
        margin-bottom: 20px;
        width: 80vw;
        // margin-left: 20px;
    }

    .successHeader {
        font-size: 14px;
    }


    .category {
        font-size: 24px;
        margin-top: 10px;
    }

    .categoryDesc {
        width: 100%;
        line-height: 130%;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
    }



    .mt {
        margin-top: 10px;
        margin-bottom: -20px;
    }




    .socialMediaDiv {
        margin-top: 10px
    }

    .mail {
        p {
            font-size: 12px;
        }
    }

    .socialMediaIcon {
        margin: 0 5px;
    }

    .number {
        width: 20px;
    }


    .score {
        font-weight: 500;
        font-size: 24px;
        line-height: 144.5%;
        color: #FB8B19;
        margin-top: 10px;
    }



    .alignCenter {
        width: 90%;
        margin: 10px auto
    }

    .categoryImage {
        width: 35%;
        height: 35%;
    }

    .textfieldDiv {
        display: flex;
    justify-content: center;
    margin-top: 5px;

        .urlTextfield {
            padding: 7px 0 7px 7px;
        }

        button {
            padding: 7.5px;
        }
    }



}



@media (max-width: 600px) {
    .stepper {
        display: none
    }
}

@media (max-width: 450px) {
    .button1 {
        margin: 40px 0;
        padding: 10px 38px;
        font-size: 16px;
        width: 70px
    }

}


@media (max-width: 400px) {
    .successHeader {
        font-size: 14px;
        width: 80%;
        display: block;
        margin: 0 auto;
    }

}
body {
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 10px;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 131.5%;
  color: #FFFFFF;
}


p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 144.5%;
  color: #FFFFFF;
}



@media only screen and (min-width: 769px) and (max-width: 1100px) {
  h1 {
    font-size: 36px;
  }

  p{
   font-size: 20px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 32px;
  }


  p {
    font-size: 20px;
  }
}